import { APP_ROUTES } from "./config/enum";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Password from "./pages/auth/Password";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

//Pages components
const ManageUsers = async(() => import("./pages/Users"));
const ManageUserTypes = async(() => import("./pages/UserTypes"));
const AddEditUserTypes = async(
  () => import("./pages/UserTypes/View/AddEditUserTypes")
);
const ManageAgency = async(() => import("./pages/Agency"));
const ManageDepartment = async(() => import("./pages/Department"));
const ManageHeadProject = async(() => import("./pages/HeadProject"));
const ManageProject = async(() => import("./pages/Project"));
const ManageQualification = async(() => import("./pages/Qualification"));
const ManageLead = async(() => import("./pages/Lead"));
const ManageConvertedLead = async(() => import("./pages/ConvertedLead"));
const ManageFlyedLead = async(() => import("./pages/FlyedLead"));

const routes = [
  {
    path: APP_ROUTES.DASHBOARD,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: APP_ROUTES.AUTH_ROOT.replace("/", ""),
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "create-password",
        element: <Password />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: APP_ROUTES.DASHBOARD,
    element: <DashboardLayout />,
    children: [
      {
        path: "/dashboard/default",
        element: <Default />,
      },
    ],
  },
  {
    path: APP_ROUTES.USERS_LIST_PAGE,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManageUsers />,
      },
    ],
  },
  {
    path: APP_ROUTES.USER_TYPES_LIST_PAGE,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManageUserTypes />,
      },
      {
        path: "create-usertype",
        element: <AddEditUserTypes />,
      },
      {
        path: ":userTypeId",
        element: <AddEditUserTypes />,
      },
    ],
  },
  {
    path: APP_ROUTES.AGENCY_LIST_PAGE,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManageAgency />,
      },
    ],
  },
  {
    path: APP_ROUTES.DEPARTMENT_LIST_PAGE,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManageDepartment />,
      },
    ],
  },
  {
    path: APP_ROUTES.HEAD_PROJECT_LIST_PAGE,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManageHeadProject />,
      },
    ],
  },
  {
    path: APP_ROUTES.PROJECT_LIST_PAGE,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManageProject />,
      },
    ],
  },
  {
    path: APP_ROUTES.QUALIFICATION_LIST_PAGE,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManageQualification />,
      },
    ],
  },
  {
    path: APP_ROUTES.LEAD_LIST_PAGE,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManageLead />,
      },
    ],
  },
  {
    path: APP_ROUTES.CONVERTED_LEAD_LIST_PAGE,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManageConvertedLead />,
      },
    ],
  },
  {
    path: APP_ROUTES.FLYED_LEAD_LIST_PAGE,
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ManageFlyedLead />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
